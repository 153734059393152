function requestAjax(name, data, func) {
    fetch('/wp-admin/admin-ajax.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            action: name,
            data: data
        })
    })
    .then(response => response.text())
    .then(res => {
        func(res);
    })
    .catch(error => {
        console.error('Error:', error);
    });
}

