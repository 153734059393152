document.addEventListener("DOMContentLoaded", () => {
  let menuBtn = document.querySelector(".header-mobile-menu-open");
  let mobileMenu = document.querySelector(".header-mobile");
  let menuItems = document.querySelectorAll(".header-mobile-menu ul li a");

  menuBtn.addEventListener("click", () => {
    mobileMenu.classList.toggle("header-mobile_show");
  });


  menuItems.forEach(btn => {
    btn.addEventListener("click", () => {
      mobileMenu.classList.remove("header-mobile_show");
    })
  })
})