document.addEventListener("DOMContentLoaded", () => {
    let langBtns = document.querySelectorAll("[data-lang-change]");

    langBtns.forEach(btn => {
        btn.addEventListener("click", () => {
            changeLang(btn.dataset.langChange);
        })
    })
})

function changeLang(value) {
    requestAjax('change_lang', value, function(res) {
        if (res) {
            window.location.reload();
        }
    });
}


