document.addEventListener("DOMContentLoaded", () => {
  let catBtns = document.querySelectorAll("[data-target-category]");
  let blogs = document.querySelectorAll("[data-category]");

  catBtns.forEach(btn => {
    btn.addEventListener("click", () => {
      catBtns.forEach(btn => btn.classList.remove("blog-tags-item_active"));
      btn.classList.add("blog-tags-item_active");

      if (btn.dataset.targetCategory === "all") {
        blogs.forEach(blog => blog.classList.add("blog-item_active"));
      } else {
        let currentBlogs = document.querySelectorAll(`[data-category="${btn.dataset.targetCategory}"]`);
        blogs.forEach(blog => blog.classList.remove("blog-item_active"));
        currentBlogs.forEach(blog => blog.classList.add("blog-item_active"));
      }
    })
  })
})

document.addEventListener("DOMContentLoaded", () => {
  let blogSection = document.querySelector(".blog");
  let isMobile = window.matchMedia("(max-width: 768px)");

  let observeBtnsOption = {
    root: null,
    threshold: .1,
    rootMargin: "-100px 0px 0px 0px",
  }

  let observerBtns = new IntersectionObserver(onIntersection, observeBtnsOption)

  function onIntersection(entries, opts){
    entries.forEach(entry => {
        blogSection.classList.toggle('blog_visible', entry.isIntersecting)
      }
    )
  }

  observerBtns.observe(document.querySelector('.blog'));
})