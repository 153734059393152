document.addEventListener("DOMContentLoaded", () => {
  let playBnts = document.querySelectorAll("[data-play]");

  playBnts.forEach(btn => {
      btn.addEventListener("click", () => {
          let preview = btn.closest(".faq-video__preview");
          let video = document.querySelector(`[data-play-video="${btn.dataset.play}"]`);

          console.log(video);

          preview.classList.add('faq-video__preview_hide');
          video.play();
      })
  })
})